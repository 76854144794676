// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-recipe-js": () => import("/opt/build/repo/src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-drupalcamp-js": () => import("/opt/build/repo/src/pages/drupalcamp.js" /* webpackChunkName: "component---src-pages-drupalcamp-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ithacawebpeople-js": () => import("/opt/build/repo/src/pages/ithacawebpeople.js" /* webpackChunkName: "component---src-pages-ithacawebpeople-js" */),
  "component---src-pages-umami-js": () => import("/opt/build/repo/src/pages/umami.js" /* webpackChunkName: "component---src-pages-umami-js" */),
  "component---src-pages-umami-articles-js": () => import("/opt/build/repo/src/pages/umami/articles.js" /* webpackChunkName: "component---src-pages-umami-articles-js" */),
  "component---src-pages-umami-recipes-js": () => import("/opt/build/repo/src/pages/umami/recipes.js" /* webpackChunkName: "component---src-pages-umami-recipes-js" */)
}

